import { render, staticRenderFns } from "./SellCreate.vue?vue&type=template&id=586a8a06&scoped=true"
import script from "./SellCreate.vue?vue&type=script&lang=js"
export * from "./SellCreate.vue?vue&type=script&lang=js"
import style0 from "./SellCreate.vue?vue&type=style&index=0&id=586a8a06&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "586a8a06",
  null
  
)

export default component.exports